import React, { useState } from "react";
import BlogCard from "../../components/blogCard";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import { useStaticQuery, graphql } from "gatsby";
import { BsArrowRight } from "react-icons/bs";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const BlogPage = () => {
	const [shortenPosts, setShortenPosts] = useState(true);
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				blogPosts: allWpPost(
					sort: { fields: dateGmt, order: DESC }
					filter: { title: { ne: "Website Images" } }
				) {
					nodes {
						dateGmt(formatString: "MM.DD.yyyy")
						id
						title
						slug
						excerpt
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											quality: 100
											placeholder: BLURRED
											layout: CONSTRAINED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const siteUrl = data.site.siteMetadata.siteUrl;
	const handleClick = () => {
		setShortenPosts(false);
	};
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Blog | Denis Bonnici Electricians Ltd"
				description="Denis Bonnici Electricians Blog"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog`,
					title: "Blog | Denis Bonnici Electricians Ltd",
					description: "Denis Bonnici Electricians Blog",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero backgroundImage={heroImg} title="Blog" />
			<div>
				<section>
					{shortenPosts === true && (
						<div>
							{data.blogPosts.nodes.slice(0, 5).map(function (post, index) {
								if (index % 2 !== 0) {
									return (
										<BlogCard
											key={post.id}
											title={post.title}
											uri={post.slug}
											date={post.dateGmt}
										></BlogCard>
									);
								} else {
									return (
										<BlogCard
											key={post.id}
											title={post.title}
											uri={post.slug}
											date={post.dateGmt}
											bgDark={true}
										></BlogCard>
									);
								}
							})}
						</div>
					)}
					{shortenPosts === false && (
						<div>
							{data.blogPosts.nodes.map(function (post, index) {
								if (index % 2 !== 0) {
									return (
										<BlogCard
											key={post.id}
											title={post.title}
											uri={post.slug}
											date={post.dateGmt}
										></BlogCard>
									);
								} else {
									return (
										<BlogCard
											key={post.id}
											title={post.title}
											uri={post.slug}
											date={post.dateGmt}
											bgDark={true}
										></BlogCard>
									);
								}
							})}
						</div>
					)}
				</section>
				{shortenPosts === true && (
					<section className="bg-bannerBg text-center py-4">
						<p
							className="older-posts  pb-0 mb-0 fs-2 fw-bold"
							onClick={handleClick}
						>
							Older posts <BsArrowRight />
						</p>{" "}
					</section>
				)}
			</div>
		</Layout>
	);
};

export default BlogPage;
