import React from "react";

import Link from "./link";

const BlogCard = ({ title, date, uri, bgDark }) => {
	return (
		<section
			className={`${bgDark ? "bg-primary" : "bg-bgLight"} text-center py-5`}
			lg={6}
		>
			<p className="text-white mb-0">{date}</p>
			<Link to={`/blog/${uri}`} className="text-white fs-2 fw-bold">
				{title}
			</Link>
		</section>
	);
};

export default BlogCard;
